@import-normalize;
@import './font.css';
@import './button.css';
@import './recipe-list.css';
@import './recipe.css';
@import './recipe-edit.css';
@import './ingredient-list.css';

*, *::before, *::after {
    font-family: Gotham Rounded, sans-serif;
    box-sizing: border-box;
}

html {
    font-size: 25px;
}

body {
    padding: 0;
    margin: 0;
}

.mr-1 {
    margin-right: 5px;
}